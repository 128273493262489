<template>
  <MyDialog :isShow="show" @handleClose="handleClose" title="温馨提示" width="400px" height="350px"
            :position="options">
    <div class="content">
      <slot name="title">
        <p class="tac fs18">{{title}}</p>
      </slot>

      <img src="https://img.infox-med.com/webImg/infoPc/downloadAppCode.png" alt="">
      <slot name="foot">
        <p class="fs16">电脑端翻译功能待完善</p>
        <p class="fs16">请下载APP使用翻译功能</p>
      </slot>
    </div>
  </MyDialog>
</template>

<script>
import MyDialog from '@c/myDialog/newIndex.vue'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '全文翻译'
    },
    options: {
      type: Object,
      default: () => ({ x: '50%', y: '50%' })
    }
  },
  components: { MyDialog },
  data () {
    return {

    }
  },
  methods: {

    handleClose () {
      this.$emit('update:show', false)
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.content {
  display: grid;
  justify-items: center;
  padding-top: 20px;
  img {
    width: 160px;
    height: 160px;
  }
}
/deep/.title_text {
  padding-left: 10px;
  text-align: center;
}
</style>
