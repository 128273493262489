<template>
  <div class="periodical-list ">
    <div class="periodical-row" v-for="(item,i) in list" :key="item.id">
      <div class="item_content" v-show="!isHide(i)">
        <img v-if="item.isSubscribed" class="subscribed_icon"
             src="https://img.infox-med.com/webImg/infoPc/subscribed_icon.png" alt="">
        <div class="row-left">
          <span class="update_icon flc" v-if="item.isUpdate">
            有更新
            <img class="update_icon_img"
                 src="https://img.infox-med.com/244265957200dc4f7ec84cdfc0853787.png" />
          </span>

          <el-image :src="item.cover">
            <div slot="error" class="image-slot">
              <img class="el-image" src="@/assets/img/periodical/imgErr.png" alt="">
            </div>
          </el-image>
        </div>
        <div class="row-right">
          <div @click="goJournalDetailsPage(item)" class="right-title  hand ">{{item.fullName}}
          </div>
          <div class="short_item">

            <span class="shortName">{{item.shortName}}</span>
            <div @click="toUpdateJournal(item)" class="to_update" v-if="item.isUpdate">
              <span>最新发表{{item.updateTotal}}篇</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div v-if="item.tags" class="tag-box shortName row_1">
            <span>领域: </span>
            <span>{{item.tags}}</span>
          </div>

          <div class="right-item">
            <span v-if="item.issn">ISSN: {{item.issn}}</span>
            <span v-if="item.eissn">eISSN: {{item.eissn}}</span>
          </div>
          <div class="right-item">
            <span v-if="item.sum">文献数量: {{item.sum}}</span>
            <span v-if="item.importFactor">IF: {{item.importFactor}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isEnd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },
  methods: {
    isHide (i) {
      const l = this.list.length
      // i!==list.length-1
      if (this.isEnd) return false
      if (i !== l - 1) return false
      return l % 2 && i === (l - 1)
    },
    // 前往最新发表
    toUpdateJournal (item) {
      const row = {
        ...item,
        category: 1
      }
      this.goJournalDetailsPage(row)
    },
    // 跳转到期刊详情页面
    goJournalDetailsPage (item) {
      const query = {
        journalId: item.id
      }
      if (item.category) {
        query.category = item.category
      }
      this.$myCount({
        name: 'PC-期刊详情访问',
        itemId: item.id
      })
      this.$router.push({
        path: '/journalDetails',
        query
      })
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.periodical-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .periodical-row {
    width: 49%;
  }
  .item_content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 160px;
    // height: 160px;
    border-radius: 8px;
    margin: 20px 0 0;
    padding: 20px;
    background-color: #fff;
    &:hover {
      // box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.5);
      box-shadow: 6px 6px 7px rgba(204, 204, 204, 0.5), -2px -3px 7px #ffffff;
      transition: all 0.3s;
      .subscribed_icon {
        top: -2px;
        transition: all 0.3s;
      }
    }
    .subscribed_icon {
      position: absolute;
      top: 0;
      right: 10px;
      width: 18px;
      z-index: 99;
    }
    .row-left {
      position: relative;
      width: 96px;
      height: 136px;
      margin-right: 20px;
      .update_icon {
        position: absolute;
        left: 0;
        top: 0;
        height: 20px;
        width: 58px;
        z-index: 99;
        color: #fff;
        .update_icon_img {
          position: absolute;
          left: 0;
          top: 0;
          height: 20px;
          z-index: -1;
        }
      }
      .el-image {
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }
    }

    .row-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - 116px);
      height: 100%;
      // background-color: pink;
      .right-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all; //强制换行
        // word-wrap: break-word; // 单词换行
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
        &:hover {
          text-decoration: underline;
        }
      }
      .short_item {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        justify-content: space-between;
        .shortName {
          font-size: 14px;
          color: #4d4d4d;
        }
        .to_update {
          color: #4b639f;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .right-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #4d4d4d;
      }
      .tag-box {
        width: 100%;
      }
      .tag {
        padding: 0 5px;
        border-right: 1px solid #ccc;
        &:last-child {
          padding-right: 0;
          border: 0;
        }
      }
    }
  }
}
</style>
