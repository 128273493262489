<template>
  <!-- 期刊列表页 -->
  <div class="journal" ref="journal">
    <div class="journal-box">
      <div class="journal-item">
        <div class="journal_item_top_box no_select">
          <span class="page_warning_text">>>您正在访问期刊</span>
          <span @click="onOpenJournalSearch"
                class="journal_search_btn  hand">{{isJournalSearch?'关闭搜索' : '期刊搜索'}}</span>
        </div>
        <div v-show="!isJournalSearch" class="tab no_select">
          <div class="title">学科:</div>
          <el-tabs v-model="categoryId" @tab-click="handleClick"
                   :class="{'all_tab':customCategoryList.length === 0}">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in customCategoryList" :key="item.id"
                         :label="item.secondCategory" :name="item.id.toString()"></el-tab-pane>
          </el-tabs>
        </div>
        <div v-show="!isJournalSearch" class="no_select warn_container">
          <div class="warn_text">
            <span class="mr10 bold">温馨提示:</span>
            <span>您可以前往APP订阅感兴趣的学科，将会同步到这里</span>
          </div>
          <div class="download_btn hand" ref="openDialogBtn" @click="openDownload">下载 Info X Med
            APP，随时阅读>></div>
        </div>

        <div v-show="isJournalSearch" class="journal_search_box">
          <div class="input_search_box">
            <el-autocomplete class="inline-input" v-model="keywords"
                             :fetch-suggestions="querySearch" placeholder="输入期刊关键词"
                             :trigger-on-focus="false" @select="handleSelect" clearable>
              <template v-slot="{item}">
                <div class="journal_suggestions_box">
                  <span v-html="item.fullName" class="journal_fullName"></span>
                  <span v-html="item.shortName" class="journal_shortName"></span>
                </div>
              </template>
            </el-autocomplete>
            <span @click="onJournalSearch" class="journal_search_btn search_btn  hand">搜索</span>
          </div>
          <!-- if值筛选  isJournalSearchList-->
          <div class="screen_input_box" v-if="isJournalSearchList">
            <div class="screen_val_box">
              影响指数区间: {{ifScreenVal}}
            </div>
            <div v-if="!isShowIfScreenInput" @click="onOpenScreenInputBox"
                 class="screen_change_btn  hand">修改影响指数区间</div>
            <div v-if="isShowIfScreenInput" class="screen_comtentinput_box">
              <el-input size="mini" v-model="docIf[0]" placeholder=""></el-input>
              <el-input size="mini" v-model="docIf[1]" placeholder=""></el-input>
              <span @click="onIfScreenBtn" class="screen_btn">筛选</span>
              <span @click="onResetScreenBtn" class="resetScreen_btn">重置</span>
            </div>
          </div>
        </div>
        <JouranlList :list="list" :isEnd="!isMoreBtn" />
        <!-- 加载更多按钮 -->
        <div class="bottom-btn literature">
          <!-- <i class="el-icon-d-arrow-right"></i> -->
          <span v-if="isMoreBtn" @click="seeMore" class=" hand">查看更多>></span>
          <span v-else style="color:#9195a3;">暂无更多</span>
        </div>
      </div>
    </div>
    <DownloadApp :show.sync="showDownloadApp" :options="dialogOptions">
      <template #title>
        <div class="download_btn fs16 mb10">
          下载 Info X Med APP，随时阅读
        </div>
      </template>
      <template #foot>
        <div class="foot_box fs14">
          扫码下载APP
        </div>
      </template>
    </DownloadApp>
  </div>
</template>
<script>
import { getCustomCategoryList, getJournalListByCategory, journalSearchSuggest, journalSearch, getSubscribedJournal, getMenuSubscribedJournal } from '@/api/classification'
import { JournalDocumentSum } from '@/utils/index.js'
import JouranlList from './components/journalList.vue'
import DownloadApp from '@c/downloadAppDlalog/index.vue'
export default {
  components: {
    JouranlList,
    DownloadApp
  },
  data () {
    return {
      categoryId: '0', // tab选中的id
      customCategoryList: [], // 用户自定义的分类列表
      list: [], // 期刊列表
      // 分页参数
      pagesObj: {
        pageNum: 1,
        pageSize: 10
      },
      isMoreBtn: false, // 查看更多状态位
      isJournalSearch: false, // 期刊搜索状态位
      keywords: '', // 期刊搜索关键字
      docIf: [], // if值筛选条件
      isShowIfScreenInput: false, // if筛选输入框状态位
      isifScreen: false, // 是否为筛选结果
      ifScreenVal: '默认', // if值筛选条件
      isJournalSearchList: false, // 是否为搜索的结果
      dialogOptions: {},
      showDownloadApp: false
    }
  },
  methods: {
    setdialogOptions () {
      const boxInfo = this.$refs?.openDialogBtn?.getBoundingClientRect()
      this.dialogOptions = {
        x: boxInfo.x + 111,
        y: boxInfo.y + 10
      }
    },
    openDownload () {
      this.setdialogOptions()
      this.$nextTick(() => (this.showDownloadApp = true))
    },
    async getSubscribedList () {
      const serve = this.categoryId !== '0' ? getMenuSubscribedJournal : getSubscribedJournal
      const data = {
        menuId: this.categoryId
      }
      const res = await (this.categoryId === '0' ? serve() : serve(data))
      this.list = this.setJournalList(res.data).map(item => ({
        ...item,
        isSubscribed: true
      }))
      this.getList()
    },
    // if筛选重置
    onResetScreenBtn () {
      this.isifScreen = false
      this.list = []
      this.docIf = []
      this.ifScreenVal = '默认'
      this.journalSearch()
    },
    // if筛选按钮
    onIfScreenBtn () {
      if (!this.keywords) return this.$message.warning('请填写搜索关键词')
      this.isifScreen = true
      this.list = []
      this.journalSearch()
    },
    // 打开if筛选输入框
    onOpenScreenInputBox () {
      if (this.list.length === 0) return this.$message.warning('暂无结果请更换搜索关键词')
      this.isShowIfScreenInput = true
    },
    // 关键字高亮
    keysLight (content, keyWord) {
      if (!keyWord) return content
      // 搜索文字处理:  以空格分隔关键字, 去重，倒序(先匹配文字多的，再匹配文字少的)
      const searchArray = keyWord.split(' ').filter(item => item.length)
      const keys = [...new Set(searchArray)].sort((a, b) => { return b.length - a.length })
      let text = content
      keys.forEach(key => {
        let num = -1 // 存放html数组对应的索引
        const regKey = new RegExp(key, 'gi')
        const val = text.match(regKey) ? text.match(regKey)[0] : key
        const stringKey = `<span style="font-weight: 600; font-size: 16px;color: #c05600; ">${val}</span>`
        const regHtml = new RegExp('<.*?>', 'ig') // 匹配html元素
        const arrayHtml = text.match(regHtml) // 存放html元素的数组
        text = text.replace(regHtml, '{~}') // 替换html标签
        text = text.replace(regKey, stringKey) // 替换关键字
        text = text.replace(/{~}/g, function () {
          // 恢复html标签
          num++
          return arrayHtml[num]
        })
      })
      return text
    },
    // 搜索按钮事件
    onJournalSearch () {
      this.pagesObj.pageNum = 1
      this.list = []
      this.docIf = []
      this.$myCount('PC-搜索期刊')
      this.journalSearch()
    },
    setJournalList (list) {
      return list.reduce((res, item) => {
        const find = this.list.find(find => find.id === item.id)
        if (find) return res
        // new Date(item.updateCount.split(':')[0]).getTiem()
        res.push({
          ...item,
          tags: item.tags ? item.tags.slice(0, item.tags.length - 1).replace(/;/g, ' | ') : '',
          sum: item.yearsCount && JournalDocumentSum(item.yearsCount),
          updateTotal: item.updateCount ? item.updateCount.split(':')[1] : 0,
          updateTime: item.updateCount ? new Date(item.updateCount.split(':')[0]).getTime() : 0,
          isUpdate: item.updateCount ? this.setUpdateState(item.updateCount.split(':')[0]) : false //  && new Date(item.updateCount.split(':')[0]).getTime()
        })
        return res
      }, [])
    },
    setUpdateState (time) {
      const updateTime = new Date(time).getTime() // + 86400000 * 2
      const currentTime = new Date(this.$initTime(new Date()) + ' 00:00:00').getTime() - 86400000
      return updateTime >= currentTime
    },
    // 期刊搜索获取数据
    async journalSearch () {
      const data = {
        ...this.pagesObj,
        keywords: this.keywords
      }
      if (this.isifScreen) {
        // if筛选
        if (this.docIf.length < 2) return this.$message.warning('请填写完整的if筛选范围')
        if (isNaN(Number(this.docIf[0])) || isNaN(Number(this.docIf[1]))) return this.$message.warning('请填写数字')
        data.docIf = this.docIf.join('-')
        this.ifScreenVal = data.docIf
      }
      const res = await journalSearch(data)
      this.isJournalSearchList = true
      this.isMoreBtn = res.data && res.data.length >= this.pagesObj.pageSize
      if (res.data === null) return
      this.list = [...this.list, ...this.setJournalList(res.data)]
    },
    // 激活方法
    handleSelect (item) {
      const regHtml = new RegExp('<.*?>', 'ig')
      this.keywords = item.fullName.replace(regHtml, '')
      this.pagesObj.pageNum = 1
      this.list = []
      this.$myCount('PC-搜索期刊')
      this.journalSearch()
    },
    // 搜索建议
    async querySearch (queryString, cb) {
      const res = await journalSearchSuggest({
        keywords: queryString,
        pageSize: 10
      })
      const list = res.data.length > 0 ? res.data.map(item => ({ fullName: this.keysLight(item.fullName, queryString), shortName: this.keysLight(item.shortName, queryString) })) : res.data
      cb(list)
    },
    // 打开期刊搜索
    onOpenJournalSearch () {
      this.isJournalSearch = !this.isJournalSearch
      if (!this.isJournalSearch) {
        // console.log('关闭搜索')
        this.pagesObj.pageNum = 1
        this.keywords = ''
        // this.getCustomCategoryList()
        this.list = []
        this.docIf = []
        this.isShowIfScreenInput = false
        this.isifScreen = false
        this.ifScreenVal = '默认'
        this.isJournalSearchList = false
        this.getSubscribedList()
      }
    },

    // 查看更多
    seeMore () {
      this.pagesObj.pageNum++
      this.isJournalSearch ? this.journalSearch() : this.getList()
    },
    //  根据分类获取期刊列表
    async getList () {
      try {
        const res = await getJournalListByCategory({
          ...this.pagesObj,
          categoryId: this.categoryId
        })
        this.isMoreBtn = res.data && res.data.length >= this.pagesObj.pageSize
        if (res.data === null) return
        this.list = [...this.list, ...this.setJournalList(res.data)]
      } catch (error) {
        this.$message.error('期刊列表获取失败')
      }
    },
    // 获取用户自定义分类列表
    async getCustomCategoryList () {
      try {
        const res = await getCustomCategoryList()
        this.customCategoryList = res.data
        this.getSubscribedList()
      } catch (error) {
        this.$message.error('获取分类列表失败')
      }
    },
    // tab栏切换事件
    handleClick () {
      // if (val?.name === this.categoryId) return
      this.pagesObj.pageNum = 1
      this.list = []
      // this.getList()
      this.getSubscribedList()
      document.documentElement.scrollTop = 0
      // if (document.documentElement.scrollTop > 250) {
      //   document.documentElement.scrollTop = 250
      // }
    }
  },
  watch: {
    $route (to, form) {
      if (to.path !== '/periodical' && form.path !== '/journalDetails' && to.path !== '/journalDetails') {
        this.pagesObj.pageNum = 1
        this.keywords = ''
        this.isJournalSearch = false
        this.docIf = []
        this.isShowIfScreenInput = false
        this.isifScreen = false
        this.ifScreenVal = '默认'
        this.isJournalSearchList = false
        this.list = []
        this.getSubscribedList()
      }
    }
  },
  created () {
    this.getCustomCategoryList() // 获取用户分类列表
    // this.getList() // 获取期刊信息
  }
}
</script>
<style lang="less" scoped>
.journal-box {
  min-height: 100vh;
  background-color: #f5f7fb;

  .journal-item {
    width: 1200px;
    // height: 100%;
    padding: 10px 0px;
    margin: 0 auto;
    .journal_item_top_box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .page_warning_text {
      font-size: 14px;
      letter-spacing: 2px;
      color: #4b639f;
    }
    .journal_search_btn {
      padding: 5px 10px;
      // background-color: #fff;
      border-radius: 5px;
      color: #4b639f;
      border: 1px solid #4b639f;
      transition: all 0.3s;
      &:hover {
        background-color: #4b639f;
        color: #fff;
      }
    }
    .tab {
      width: 1200px;
      height: 50px;
      display: flex;
      align-items: center;
      position: -webkit-sticky;
      position: sticky;
      top: 52px;
      z-index: 100;
      padding: 10px 0 10px 0px;
      // margin: 0 0px;
      background-color: #fff;
      overflow: hidden;
      border-bottom: 1px solid #e8e8e8;
      .title {
        font-size: 18px;
        font-weight: 700;
        margin: 0 10px 0 20px;
        flex-shrink: 0;
      }
      /deep/ .el-tabs {
        width: calc(100% - 95px);
        .el-icon-arrow-left:before,
        .el-icon-arrow-right::before {
          position: relative;
          top: -1px;
          padding: 2px;
          border-radius: 50%;
          // border: 1px solid #4b639f;
          color: #4b639f;
          font-size: 16px;
          // background-color: #d4dff5;
          font-weight: 700;
        }
        .el-icon-arrow-left:before {
          content: '\e6dd';
        }
        .el-icon-arrow-right:before {
          content: '\e6dc';
        }
      }
      /deep/.el-tabs__item {
        font-size: 16px;
        padding: 0 10px;
        font-weight: 400;
        &:hover {
          color: #4b639f;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        width: 0;
        height: 0;
      }
      /deep/ .is-active {
        font-weight: 600;
        color: #4b639f;
        // background-color: #f0f6ff;
      }
      /deep/.el-tabs__active-bar {
        // display: none;
        background-color: #4b639f;
      }
    }
    .all_tab {
      /deep/.el-tabs__active-bar {
        width: 34px !important;
      }
    }
    .warn_container {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
      height: 40px;
      background-color: #fff;
      font-size: 14px;
      padding: 0 20px;
    }

    // 期刊搜索盒子样式
    .input_search_box {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 109px;
      padding: 10px 0 10px;
      width: 100%;
      .el-autocomplete {
        width: 60%;
        height: 40px;
      }
      /deep/ .el-input__inner:focus {
        border-color: #4b639f;
      }
      .search_btn {
        padding: 8px 20px;
        margin-left: 20px;
      }
    }
    // 筛选if值盒子
    .screen_input_box {
      display: flex;
      align-items: center;
      margin-top: 5px;
      .screen_val_box {
        margin-right: 20px;
      }
      .screen_change_btn {
        font-size: 14px;
        color: #4b639f;
      }
      .screen_comtentinput_box {
        display: flex;
        align-items: center;
        margin-left: 20px;
        .el-input {
          width: 80px;
          margin-right: 20px;
          /deep/ .el-input__inner:focus {
            border-color: #4b639f;
          }
        }
        .screen_btn,
        .resetScreen_btn {
          padding: 3px 10px;
          font-size: 14px;
          cursor: pointer;
          border-radius: 3px;
        }
        .screen_btn {
          background-color: #4b639f;
          color: #fff;
          margin: 0 10px 0 20px;
          &:hover {
            opacity: 0.9;
          }
        }
        .resetScreen_btn {
          color: #4b639f;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    // 底部加载更多样式
    .bottom-btn {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      color: #4b639f;
      .hand:hover {
        font-weight: 700;
      }
    }
  }
}
.download_btn {
  color: #ff832a;
}
.foot_box {
  letter-spacing: 5px;
}
</style>
