import request from '../utils/request.js'
// 期刊接口

// 获取用户自定义分类
export function getCustomCategoryList (data) {
  return request({
    // url: '/home/getCustomCategoryList',
    url: 'home/getCustomCategoryList2',
    method: 'POST',
    data
  })
}

// 根据指南分类id获取指南详情 （停用）
export function getDocumentListByCategory (data) {
  return request({
    url: '/home/getDocumentListByCategory',
    method: 'POST',
    data
  })
}

// 根据分类ID获取期刊列表
export function getJournalListByCategory (data) {
  return request({
    url: '/home/getJournalListByCategory',
    method: 'POST',
    data
  })
}

// 获取期刊详情
export function getJournalInfo (data) {
  return request({
    url: '/home/getJournalInfo',
    method: 'POST',
    data
  })
}

// 根据杂志id和日期获取文献列表
export function getDocumentListByJournal (data) {
  return request({
    url: '/home/getDocumentListByJournal',
    method: 'POST',
    data
  })
}

// 根据期刊名字获取期刊详细信息
export function getJournalInfoByJournalName (data) {
  return request({
    url: '/home/getJournalInfoByJournalName',
    method: 'POST',
    data
  })
}

// 根据期刊id获取期刊详情
export function getJournalDetails (data) {
  return request({
    url: 'home/getJournalFullInfo',
    method: 'POST',
    data
  })
}

// 期刊搜索建议
export function journalSearchSuggest (data) {
  return request({
    url: '/home/journalSearchSuggest',
    method: 'POST',
    data
  })
}

// 期刊搜索
export function journalSearch (data) {
  return request({
    url: '/home/journalSearch',
    method: 'POST',
    data
  })
}

// 获取订阅期刊列表
export function getSubscribedJournal (data) {
  return request({
    url: 'home/getSubscribedJournalList',
    method: 'POST',
    data
  })
}
// 根据订阅获取期刊列表
export function getMenuSubscribedJournal (data) {
  return request({
    url: 'home/getSubscribedJournalListByMenuId',
    method: 'POST',
    data
  })
}
